import type { FunctionComponent } from 'react';
import type { BrandColor } from '../../types/color';
import { Star } from '../icons/star';
import styles from './star-rating.module.scss';

// Sizes in pixel values
const SIZES = {
  xsmall: 12,
  small: 24,
  large: 40
};

/** Displays star equivalent of value between 1 and 5. */
export const StarRating: FunctionComponent<React.PropsWithChildren<Props>> = ({
  value,
  color,
  starSize = 'large',
  label
}) => {
  const size = SIZES[starSize];
  const isValidNumber = RegExp(/^([0-5]|([0-4]\.\d+))$/).test(value.toString());
  if (!isValidNumber) {
    throw new Error('Value must be between 0 and 5');
  }
  return <div className={styles.rating} aria-label={label} data-testid='review.starRating' data-sentry-component="StarRating" data-sentry-source-file="index.tsx">
            {generateStarPercents(value).map((percent, index) => <Star percent={percent} key={index} color={color} width={size} role='presentation' data-value={value} />)}
        </div>;
};
export const generateStarPercents = (rating: string | number) => {
  const numberRating = typeof rating === 'string' ? parseFloat(rating) : rating;

  // Calculate the number of filled stars
  const filledStars = Math.floor(numberRating);

  // Calculate the percentage of the partially filled star, if any
  const partialStarPercent = Math.round((numberRating - filledStars) * 100);

  // Create an array of 5 empty percentages
  const ratingArray = new Array(5).fill(0);

  // Fill the array with percentages for the filled stars
  for (let i = 0; i < filledStars; i++) {
    ratingArray[i] = 100;
  }

  // If there is a partially filled star, add its percentage to the array
  if (partialStarPercent > 0) {
    ratingArray[filledStars] = partialStarPercent;
  }
  return ratingArray;
};
interface Props {
  /** Rated out of 5 */
  value: number | string;
  /** Star color */
  color: BrandColor;
  /** Star width and height */
  starSize: 'xsmall' | 'small' | 'large';
  /** Label for accessibility. */
  label?: string;
}