import classNames from 'classnames';
import type { FunctionComponent } from 'react';
import { Text } from '../../components/text';
import type { ReviewSectionFragment } from '../../graphql/fragments/ReviewSectionFragment.graphql';
import styles from './review-carousel.module.scss';
import { ReviewCard } from './ReviewCard';
export const ReviewCarousel: FunctionComponent<ReviewCarouselProps> = ({
  reviews
}) => {
  return <div className={styles.container} data-sentry-component="ReviewCarousel" data-sentry-source-file="review-carousel.tsx">
            <div className={styles['overflow-hidden']}>
                <div className={styles['scroll-row']}>
                    {reviews?.items.map((item, index) => <ReviewCard index={index} key={index} title={item?.title} titleColor={item?.titleColor} titleSizeDesktop={item?.titleSizeDesktop} titleSizeMobile={item?.titleSizeMobile} text={item?.text} textColor={item.textColor} textSizeDesktop={item.textSizeDesktop} textSizeMobile={item.textSizeMobile} author={item?.author} authorSize={item?.authorSize} rating={item.rating} ratingColor={item.starRatingColor} backgroundColor={item.backgroundColor} className={classNames(classNames(styles.card, styles['review-card']))} />)}
                </div>
            </div>

            <div className={styles['reivew-disclaimer']}>
                <Text typeStyle='body-xs' tag='p' color='dim' data-sentry-element="Text" data-sentry-source-file="review-carousel.tsx">
                    Participants may be compensated for their participation.
                    Numbers are for illustrative purposes only.
                </Text>
            </div>
        </div>;
};
export default ReviewCarousel;
export type ReviewCarouselProps = Omit<ReviewSectionFragment, 'sys' | 'type'>;